@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Alata', sans-serif;
}

body{
  background-color: #3c4359;
}
/*************************************/
/***********    GALAXY   ************/
/*************************************/

.blur
{
  position: fixed;
  display: 'block';
  opacity:0;
  background-color: black;
  width: 0px;
  height: 0px;
  transition: opacity 0.7s linear;

}

.cart .Product .icon_2 {
  position: absolute;
    top: 27px;
}

.tooltip {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100px;
  text-align: center;
  padding: 5px 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
  opacity: 0;
  color: white;
  transition: opacity 0.25s linear;
}

.description
{
  font-size:1em;
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 300px;
  width: 300px;
  left: 100px;
  text-align: center;
  padding: 5px 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
  opacity: 0;
  color: white;
  transition: opacity 0.7s linear;
}


.description a, .tooltip a{
  color: white;
}

a{
  text-decoration: none;
}

li {
  list-style-type: none;
}

html,
body,
#root,
canvas {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100%;
  height: 722px;
  position: absolute;
  top: 0;
  z-index: 0;
}
/*************************************/
/***********    HEADER    ************/
/*************************************/

#logo {
  font-weight: normal;
  font-family: 'Lilita One', cursive;
  color:#F2F4F3;
  font-size: 3rem;
}

.header-bar {
  height: 70px;
  display: flex;
  color: #F2F4F3;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  position: relative;
  z-index: 999;
}

.header-bar ul{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.header-bar li {
  display: grid;
  grid-auto-flow: column;
  position: relative;
}

.header-bar li:hover span{
  -webkit-animation: flash 1s;
          animation: flash 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  cursor: pointer;
}

@-webkit-keyframes flash {
	from {
		margin-left:100px;
	}
	to {
    margin-left:-5px;
  }
}

@keyframes flash {
	from {
		margin-left:100px;
	}
	to {
    margin-left:-5px;
  }
}

.header-bar li span {
  display: none;
  margin-left: 5px;
  position: absolute;
  top: 25px;
}

.header-bar li:hover img.icon{
  transform: scale(1.1);
  transition-duration: 0.5s;
  margin-left: 5px;
}

.header-bar li:hover a small{
  width: auto;
  height: 17px;
  font-size: 0.7rem;
  transition-duration: 0.5s;
}
.header-bar li:hover > span{
    display: inline;
    display: initial;
}

.header-bar img.icon{
  transition-duration: 0.5s;
  margin: 0 5px;
}

.header-bar li a small{
  color: #F2F4F3;
  padding: 4px;
  background-color: #c73a3a;
  position: absolute;
  right: 0;
  width: auto;
  height: 15px;
  font-size: 0.6rem;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  top: 15px;
  transition-duration: 0.5s;
}

.header-bar #searchBar {
  border-radius: 15px;
  padding: 6px 6px;
  width: 400px;
  border: none;
  z-index: 10;
  position: relative;
  height: 30px;
  background-color: #F2F4F3;
  box-shadow: 0px 0px 8px;
}

.header-bar #searchBar:focus{
  background-color: #F2F4F3;
  transition-duration: 0.3s;
}

.header-bar .resultSearchBar {
  position: absolute;
  width: 500px;
  background: #F2F4F3;
  top: 15px;
  border-radius: 15px;
  padding: 25px 0;
  height: 450px;
  overflow: auto;
  border: 9px solid #F2F4F3;
}

.header-bar .SearchResultItem {
  display: grid;
  grid-auto-flow: column;
  padding: 5px 15px 5px 0;
  color: #3c4359;
}

.header-bar a {
  color: #3c4359;
}

.header-bar .SearchResultItem:hover {
  background-color: #e4e4e4;
  border-radius: 8px;
}

.header-bar .SearchResultItem button{
  float: right;
}
.addToCart{
  border-radius: 15px;
  border: none;
  padding: 5px 15px;
  background-color: #68f375;
  transition-duration: 0.5s;
}
.addToCart:hover{
  box-shadow: 0px 0px 5px black;
  transition-duration: 0.5s;
  cursor: pointer;
}
.header-bar #containerSearch{
  width: 500px;
  display: flex;
  justify-content: center;
  position: relative;
}
/*************************************/
/***********     FORM     ************/
/*************************************/

.login-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 30vw;
  margin: auto;
  padding: 35px;
  box-sizing: content-box;
  border-radius: 65px 25px;
}

.login-form h2 {
  margin-bottom: 40px;
  color: #F2F4F3;
}

.form-error {
  position: absolute;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
  color: #fa7575;
}
.form-input {
  width: inherit;
  position: relative;
  height: 40px;
  margin-bottom: 20px;
}

.login-form input {
  width: inherit;
  border: none;
  padding: 0px 15px;
  font-size: 1rem;
  border-radius: 50px;
  height: inherit;
}

.login-form button {
  width: 100px;
  padding: 5px;
  border-radius: 50px;
  border: none;
}

.login-form .form-bottom{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

/*************************************/
/***********   PANIER     ************/
/*************************************/

.cart h2{
  text-align: center;
  padding: 30px 0;
  color: #3c4359;
  letter-spacing: 3px;
}

.cart {
  display: flex;
  justify-content: space-around;
}

.Cart-Left .items {
  display: grid;
}
.Cart-Left{
  width: 59%;
}
.Cart-Right{
  width: 25%;
}

.Cart-Left, .Cart-Right{
  background: #F2F4F3;
  border-radius: 10px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding-bottom: 10px;
}

.Cart-Right h3{
  width: 80%;
  margin: 20px auto;
}

.Product{
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
}
.Product:hover{
  background-color: #eee;
}

.cart .Content {
  margin-left: 10px;
}

/* .cart .Product > img{
  position: absolute;
  right: 0;
  top:0;
  padding: 10px;
} */

.payment{
  width: 80%;
  height: 40px;
  display: block;
  margin: auto;
  border-radius: 5px;
  border: none;
  background-color: #68f375;
  transition-duration: 0.3s;
}

.payment:hover {
  box-shadow: 0px 0px 5px black;
  transition-duration: 0.3s;
}

#credit-cards {
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.credit-card{
  margin: 5px 10px;
}

.cart-error {
  background-color: #F2F4F3;
  width: 70vw;
  height: 70vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  margin: auto;
  line-height: 3rem;
}

.cart .cart-error button {
  padding: 5px 20px;
  border-radius: 15px;
  border: none;
  background: #6bed78;
}

.cart .itemsQuantity{ 
  padding: 5px;
  margin: auto;
  background-color: #cecece; 
  width: -webkit-max-content; 
  width: -moz-max-content; 
  width: max-content; 
  border-radius: 50px; 
  display: flex; 
  align-items: center; 
}

.cart .itemsQuantity > p{
  width: 30px;
  text-align: center;
}
.cart div .minus,.cart div .add{
  padding: 5px;
  background-color: #b4b4b4;
  border-radius: 50px;
  transition-duration: 0.5s;
}

.cart div .minus:hover, .cart div .add:hover {
  box-shadow: 0px 0px 2px;
  transition-duration: 0.5s;
}
/*************************************/
/***********   CATEGORY    ***********/
/*************************************/

#categoryList ,#shopList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#categoryList > div ,#shopList > div{
  margin: 30px 10px;

}

#categoryList > div img , #shopList div img{
  border-radius: 15px;
  display: flex;
  margin: auto;
}

#categoryList > div h4 ,#shopList > div h4{
  text-align: center;
  color: #F2F4F3;
}


.loading {
  padding: 10px;
  font-weight: 900;
  font-size: 3em;
  transform: translate3d(-50%, -50%, 0);
}

.content-planet-sdauhsdayudash {
  color: white;
  transform: translate(16px,-25px);
  border-bottom: white 1px solid;
  width: 200px;
  text-align: right;
  padding-bottom: 5px;

}
.categories
{
  color: #F2F4F3;
}
.about {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;
}
.about .bg_links {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: absolute;
}
.about .logo {
  width: 40px;
  height: 40px;
  z-index: 9;
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10px 7px;
  opacity: 0.9;
  transition: all 1s 0.2s ease;
  bottom: 0;
  right: 0;
}
.about .social {
  opacity: 0;
  right: 0;
  bottom: 0;
}
.about .social .icon {
  width: 100%;
  height: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: flex;
  transition: all 0.2s ease, background-color 0.4s ease;
  opacity: 0;
  border-radius: 100%;
}
.about .social.portfolio {
  transition: all 0.8s ease;
}
.about .social.portfolio .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
}
.about .social.dribbble {
  transition: all 0.3s ease;
}
.about .social.dribbble .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
}
.about .social.linkedin {
  transition: all 0.8s ease;
}
.about .social.linkedin .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
}
.about:hover {
  width: 105px;
  height: 105px;
  transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .logo {
  opacity: 1;
  transition: all 0.6s ease;
}
.about:hover .social {
  opacity: 1;
}
.about:hover .social .icon {
  opacity: 0.9;
}
.about:hover .social:hover {
  background-size: 28px;
}
.about:hover .social:hover .icon {
  background-size: 65%;
  opacity: 1;
}
.about:hover .social.portfolio {
  right: 0;
  bottom: calc(100% - 40px);
  transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.portfolio .icon:hover {
  background-color: #698fb7;
}
.about:hover .social.dribbble {
  bottom: 45%;
  right: 45%;
  transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.dribbble .icon:hover {
  background-color: #ea4c89;
}
.about:hover .social.linkedin {
  bottom: 0;
  right: calc(100% - 40px);
  transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.linkedin .icon:hover {
  background-color: #0077b5;
}


.content {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .planet {
  width: 65%;
  height: 65%;
  background-color: #546c8c;
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  transform-origin: center center;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
  -webkit-animation: planet 5s ease infinite alternate;
          animation: planet 5s ease infinite alternate;
  /* planet ring */
  /* to cover the back of the ring */
  /* planet spots */
}
@-webkit-keyframes planet {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes planet {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.content .planet .ring {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  background-color: #bacbd9;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 33% center;
  box-shadow: 2px -10px 0px rgba(0, 0, 0, 0.1), inset -5px -10px 0px rgba(0, 0, 0, 0.1);
  -webkit-animation: ring 3s ease infinite;
          animation: ring 3s ease infinite;
  /* small ball */
  /* inner ring */
}
@-webkit-keyframes ring {
  0% {
    transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
  }
  100% {
    transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
  }
}
@keyframes ring {
  0% {
    transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
  }
  100% {
    transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
  }
}
.content .planet .ring:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 30px;
  border-radius: 100%;
  background-color: #7ea1bf;
  z-index: 2;
  left: calc(0px - 5px);
  box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.2);
}
.content .planet .ring:after {
  content: "";
  position: absolute;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  background-color: #7ea1bf;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .cover-ring {
  position: absolute;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  transform: translate(0px, -17px);
  background-color: #546c8c;
  z-index: 2;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .spots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
}
.content .planet .spots span {
  width: 30px;
  height: 30px;
  background-color: #3c4359;
  position: absolute;
  border-radius: 100%;
  box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
  -webkit-animation: dots 5s ease infinite alternate;
          animation: dots 5s ease infinite alternate;
}
@-webkit-keyframes dots {
  0% {
    box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}
@keyframes dots {
  0% {
    box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}
.content .planet .spots span:nth-child(1) {
  top: 20px;
  right: 50px;
}
.content .planet .spots span:nth-child(2) {
  top: 40px;
  left: 50px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(3) {
  top: 80px;
  left: 20px;
  width: 25px;
  height: 25px;
}
.content .planet .spots span:nth-child(4) {
  top: 80px;
  left: 90px;
  width: 40px;
  height: 40px;
}
.content .planet .spots span:nth-child(5) {
  top: 160px;
  left: 70px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(6) {
  top: 165px;
  left: 125px;
  width: 10px;
  height: 10px;
}
.content .planet .spots span:nth-child(7) {
  top: 90px;
  left: 150px;
  width: 15px;
  height: 15px;
}
.content p {
  color: #bacbd9;
  font-size: 14px;
  z-index: 2;
  position: absolute;
  bottom: -20px;
  font-family: "Roboto Mono", monospace;
  -webkit-animation: text 4s ease infinite;
          animation: text 4s ease infinite;
  width: 100px;
  text-align: center;
}
@-webkit-keyframes text {
  0% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  25% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  50% {
    transform: translateX(30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  75% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  100% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
}
@keyframes text {
  0% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  25% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  50% {
    transform: translateX(30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  75% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  100% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
}
